export const redirects = [
  {
    from: '/products/camino-coal',
    to: '/products/reusable-cup-camino-12oz-coal',
  },
  { from: '/products/bino-coal', to: '/products/reusable-cup-bino-8oz-coal' },
  { from: '/products/trip', to: '/products/rise-fressko-flask-300ml' },
  { from: '/products/corporate-branding', to: '/pages/co-brand' },
  {
    from: '/product/tour-fressko-flask-400ml',
    to: '/products/tour-fressko-flask-13oz',
  },
  {
    from: '/product/lift-fressko-flask-500ml',
    to: '/products/lift-fressko-flask-16oz',
  },
  {
    from: '/product/insulated-glass-fressko-flask',
    to: '/products/lift-fressko-flask-16oz',
  },
  { from: '/collections/coffee-cup', to: '/collections/reusable-cups' },
  { from: '/product/sleeve/', to: '/products/bino-sleeve-black' },
  {
    from: '/product/fressko-blush-360ml',
    to: '/products/lift-fressko-flask-16oz',
  },
  { from: '/collections/water-bottles', to: '/collections/drink-bottles' },
  { from: '/collections/glass-infuser-flask', to: '/collections/glass-flasks' },
  { from: '/pages/store-locator', to: '/pages/stockists' },
];
